
.bg-aqua {
    background: white !important;
    color: #6d6c6c !important;
    height: 150px;
    padding-top: 5%;
    padding-left: 2%;
}

.bg-white {
	background: white !important;
}

.bt1p {
	border-top: 1px #dedede;
}

.pt2p-pb1p {
	padding-top: 2% !important;
	padding-bottom: 1% !important;
}

.small-box .icon{
    top:26px;
    right: 13px;
    font-size: 60px;
    color: #5f5f5f !important;
    background: #dad8d8;
    border-radius: 50%;
    width: 90px;
    text-align: center;
    height: 90px;
}
.small-box:hover .icon {
    font-size: 70px;
}
.dashboard_table{
    margin:0 !important;
    background: white;
}
.tableHeadding{
    background: #e6e6e6;
}
.bordered th td{
    border: 1px solid #d2cfcf !important;
}
.table-bordered>thead>tr>th, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>tbody>tr>td, .table-bordered>tfoot>tr>td {
    border: 1px solid #d2d2d2 !important;
}
input[type=checkbox], input[type=radio] {
    margin: 0 auto !important;
    display: block;
    width:20px;
    height:20px;
}
.dashboard_boxs{
    //margin: 0 !important;
}
