.loginbox{
    border: 1px solid silver;
    border-radius:10px;
    padding-bottom:2% !important;
    margin-top: 9% !important;
    padding: 30px;
}
.logoimg{
    width:90% !important;
    height: auto !important;
    position: relative !important;
    margin: 0 auto;
    display: block;
    border-radius: 0 !important;
}
.loginboxdiv div{
    padding-top: calc(1%) !important;
    margin-top:5px;
    margin-bottom: 15px;
}

.formcontrol label{
    font-size: 22px !important;
    background: white;
    font-weight: 700;
}
.formcontrol{
    width:100% !important;
    margin-top: 4% !important;
    margin-bottom: 4% !important;
}
.formcontrol input {
    padding: 12px 6px;
}
.MuiInput-underline-134:after {
    border-bottom: 2px solid #505050 !important;
}
.loginbt{
    border-radius: 50px !important;
    width: 100%;
    background: #9c9c9c !important;
    margin-top:2% !important;
}
.loginbt:hover{
    background:silver !important;
}
.forgot-password{
    color: #8a8a8a !important;
    padding-top:2.5% !important;
    text-transform: inherit !important;
    width: 100%;
    font-size: 16px !important;
}
.forgot-password:hover{
    background: transparent !important;
    color: black !important;
}
#forgot-password-button{
    text-align: center;
    padding-top: 11px;
}
.loginbt span{
    font-weight: 600 !important;
    color: white;
    font-size: 17px;
}
#component-error-text{
    color: red;
    font-size: 15px !important;
}
.signinlabel{
    font-size: 16px !important;
    margin-top: -10px;
}
/*------------------------------ Forgot Password ---------------------------- */
